<template>
    <b-card no-body>

        <b-modal id="edit-parameter" :title="editing ? 'Güncelle' : 'Oluştur'" no-close-on-esc no-close-on-backdrop>
            <cari-parameter-create-or-update v-model="cariParameter"></cari-parameter-create-or-update>
            <template #modal-footer><v-executer :loading="loading" :editing="editing" :action="createOrUpdateParameter"></v-executer></template>
        </b-modal>
        <v-table    ref="table"
                    :items-provider="provider"
                    :fields="fields"
                    :buttons="buttons"
                    search
                    pagination="server"></v-table>
    </b-card>
</template>

<script>
import { BCard,BFormCheckbox } from 'bootstrap-vue'
import { NetsisApi } from '@/api/netsis'
import CariParameterCreateOrUpdate from './components/CariParameterCreateOrUpdate.vue'

export default {
    components:{
        BCard,
        CariParameterCreateOrUpdate,
        BFormCheckbox,
    },
    data(){
        return{
            editing:false,
            loading:false,
            fields:[
                {name:'Cari Kod',key:'CARI_KOD',sortable:true, thClass:'w-15'},
                {name:'Vergi No',key:'VERGI_NUMARASI',sortable:true, thClass:'w-15'},
                {name:'Firma Adı',key:'CARI_ISIM',sortable:true, thClass:'w-40'},
                {name:'E-Posta',key:'CARI_EMAIL', thClass:'w-20'},
                {name:'Tonaj Düzenleme Durumu',key:'IsCounterTonnage', thClass:'w-10'},
                
            ],
            buttons:[
                { text:"E-Posta Düzenle",key:"edit-contact",icon:"EditIcon",variant:"primary",action:this.editParameter},
                // { text:"Rol Düzenle",key:"edit",icon:"Edit2Icon",variant:"secondary",action:this.editCariType },
                // { text:"Sil",key:"delete",icon:"TrashIcon",variant:"danger",action:this.delete },
            ],
            cariType:{
                CariCode:null,
                CariTypes: [],
            },
            cariParameter:{
                CariCode:null,
                Email:null,
                IsCounterTonnage:false,
                Update:false
            }
        }
    },
    methods:{
        clearForm(){
            this.editing = false
            this.cariParameter.CariCode = null
            this.cariParameter.Email = null
            this.cariParameter.IsCounterTonnage = false
            this.cariParameter.Update = false
        },
        editParameter(item){
			this.clearForm()
            this.cariParameter.Update = item.CARI_EMAIL == null ? false : true,
			this.cariParameter.CariCode = item.CARI_KOD
			this.cariParameter.Email = item.CARI_EMAIL
			this.cariParameter.IsCounterTonnage = item.IsCounterTonnage == null ? false : item.IsCounterTonnage
			this.$showModal('edit-parameter')
		},
        provider(data){
            return NetsisApi.Table(data)
        },
        createOrUpdateParameter(){
            this.loading = true
            NetsisApi.CreateOrUpdateParameter(this.cariParameter.Update,this.cariParameter).then(res=>{
                this.$result(res)
                this.loading = false
                if(res.data.Success){
                    this.$refs.table.refresh()
                    this.$hideModal('edit-parameter')
                }
            }).catch(err=>{
                this.loading = false
                this.$result(null,err)
            })
        },
        delete(item){
            this.$confirm('Silmek istediğinize emin misiniz?',`Bu işlem ${item.Name} adlı firmayı silecektir ve geri alınamaz.`).then(result=>{
                if(result){
                    FirmApi.Delete(item.Id).then(res=>{
                        if(res.data.Success) {
                            this.$refs.table.refresh()
                        }
                        this.$result(res)
                    }).catch(err=>{
                        this.$result(null,err)
                    })
                }
            })
        }
    }
}
</script>

<style>

</style>