<template>
    <div class="row">
        <h4>{{ value.CariCode }}</h4>
        <div class="col-md-12 mb-1">
            <label class="form-label">E-Posta</label>
            <input type="text" v-model="value.Email" placeholder="Email" class="form-control"/>
            <span>(E-posta bilgisini ";" ile ayırarak birden fazla tanımlayabilirsiniz.)</span>
        </div>

        <div class="col-md-12 mb-1">
            <b-form-checkbox :unchecked-value=false  v-model="value.IsCounterTonnage" >Tonaj Düzenleme Yapabilsin</b-form-checkbox>
        </div>
        
    </div>
</template>

<script>
import {SystemDataApi} from '@/api/system-data'
import {BFormCheckbox} from 'bootstrap-vue'
export default {
    components:{
        BFormCheckbox
    },
    props:{
        value:Object
    },
    data(){
        return {
            
        }
    },
    mounted(){
       
    },
    watch:{
        
    },
    methods:{
        
        
    },
    computed:{
              
    }
}
</script>

<style>
    .location-name-placeholder{
        color: rgb(0,0,0,0.5);
    }
    .dark-layout .location-name-placeholder{
        color: rgb(255,255,255,0.5);
    }
</style>