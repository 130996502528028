import axios from 'axios'

export const SystemDataApi = {
    async CariTypes(){
        const response = await axios.get('/api/systemdata/caritypes')
        return response
    },
    async Provinces(){
        const response = await axios.get('/api/systemdata/provinces')
        return response
    },
    async Districts(provinceId){
        const response = await axios.get('/api/systemdata/districts/'+provinceId)
        return response
    },
    async VehicleTransportStatuses(){
        const response = await axios.get('/api/systemdata/vehicletransportstatuses')
        return response
    }
    
}